<template>
  <main class="login">
    <form>
      <label>Email*</label>
      <input v-model="email" type="email" />
      <label>Passwort*</label>
      <input v-model="password" type="password" />
    </form>
    <teleport to="#buttongroupfooter">
      <button @click="login" class="bigbutton orange">Anmelden</button>
    </teleport>
  </main>
</template>
<script>
import { ref } from "vue";
import { postData, baseURL } from "../api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "login",
  setup() {
    const email = ref("");
    const password = ref("");
    const router = useRouter();
    const store = useStore();

    async function login() {
      let logindata = await postData(
        baseURL + "api/login",
        JSON.stringify({
          email: email.value,
          password: password.value,
        })
      );
      console.log(logindata)
      if (typeof logindata === "object" && logindata.status == "ok") {
        logindata.account.agent = "";
        store.commit("register", {
          account: logindata.account,
          token: logindata.token,
          takenimages: logindata.account.takenimages
        });
        router.push({ name: "overview" });
      } else {
        store.dispatch("firenotice", {
          notice: logindata.message,
          notice_type: "alert-danger",
        });
      }
    }
    return { login, email, password };
  },
};
</script>